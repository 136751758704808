.navbar-container {
    min-height: 8vh;
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    padding: 1rem 10rem 1rem 2.4rem;
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: #fafafa;
}

a {
    color: #32302F;
    text-decoration: none;
    font-size: 1.3rem;
}
a:hover {
    color:#376B58;
}
ul {
    display: flex;
    list-style: none;
}
li {
    padding-left: 10rem;
    position: relative;
}