.education-title {
    color: #5B606E;
    font-size: 1.5rem;
    padding-left: 8rem;
    margin-top: 1.9vh;
    font-weight: bolder;
}

.title-line {
    height: 0.2rem;
    margin: 1rem auto;
    width: 88%;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: 20px auto;
}

.card-container {
    background-color: #fff;
    width: 360px;
    height: 240px;
    border-radius: 6px;
    box-shadow: 4px 6px 8px #00000040;
    
    padding-left: 28px;
    padding-bottom: 20px;
    padding-top: 28px;
    margin-top: 40px;
}

.card-title {
    color: #5B606E;
    font-size: 1.3rem;
    font-weight: bolder;
}

.logo {
    position: relative;
    top: -88px;
    width: 48px;
}

.courses {
    margin-top: -50px;
    font-size: 1.1rem;
    font-weight: bolder;
    margin-bottom: 4px;
    color: #5B606E;
}

.title-line {
    border-bottom: 1px solid rgb(91, 96, 110);
    width: 80%;
    margin-bottom: 8px;
}
ul {
    display: block;
    color: #5B606E;
   
}
.item-container {
    display: grid;
    width: 100%;
    grid-template-columns: 85% 15%;
    height: 32px;
    line-height: 1rem;
    align-items: center;
}

.item-container li {
    padding-left: 0;
}

.item-container p {
    justify-self: center;
}
.check {
    width: 22px;
    justify-self: center;
}

@media (max-width: 900px) {
    .education-title {
       text-align: center;
       padding-left: 0;
    }
   
}