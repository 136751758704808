.box-wrapper {
    width: 100%;
    margin: 100px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: -1;
}
.shape-box {
    display: inline-block;
    position: relative;
    z-index: 1;
    height: 380px;
    margin: 20px 10px 20px;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, .12);
    overflow: hidden;
    width: 46%;
    min-width: 380px;
    border-radius: 6px;
}

.shape-box_half {
    overflow: hidden;
    text-align: left;
}

.shape-box_half:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skewY(53.5deg);
    transform-origin: top left;
    transition: \transform .4s;
    background: #fff;
    z-index: 1;
}

.shape-box>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.bg-black {
    background-color: #000;
}

.shape-box_half figcaption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 30px 30px;
    transition: \transform .4s;
    transform: translateY(100%);
    z-index: 3;
}

.shape-box_half figcaption .show-cont {
    position: absolute;
    bottom: calc(100% + 30px);
    left: 30px;
    right: 30px;
    transition: bottom .4s;
}

.card-no {
    font-size: 1.2rem;
    color: #1881A1;
    padding: 0;
    margin: 2px 0;
}

.card-main-title {
    margin-top: 0;
    font-weight: 500;
    font-size: 1rem;
    color: #292b2c;
}

.card-content {
    color: #9f9f9f;
    width: 80%;
    margin-top: 20px;
    line-height: 22px;
    font-size: 15px;
}

.read-more-btn {
    border: 2px solid #FFAB00;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 600;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: #FFAB00;
    color: #fff;
    border-radius: 2px;
    margin-top: 25px;
    text-decoration: none;
}

.read-more-btn:hover {
    background: transparent;
    color: #376B58;

}

.shape-box_half>.after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(184deg, rgba(136,185,199,1) 4%, rgba(15,121,148,1) 89%);
    opacity: 0;
    transition: opacity .6s;
}

/*On hover*/
.shape-box_half:hover:before {
    transform: skewY(20deg);
}

.shape-box_half:hover figcaption {
    transform: translateY(0);
}

.shape-box_half:hover figcaption .show-cont {
    bottom: 100%;
}

.shape-box_half:hover>.after {
    opacity: 1;
}

@media (max-width: 1000px) {
    
}